import axios from "axios";

const apiClient = {
  async login(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  async readAll(urls) {
    const response = await axios.get(urls);
    // console.log(response.data);
    return response.data;
  },
  async createUpdatedata(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  async updatedata_Put(urls, requestData) {
    console.log(urls);
    const response = await axios.put(urls, requestData, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return response.data;
  },

  async updatedata_Patch(urls, requestData) {
    const response = await axios.patch(urls, requestData);
    return response.data;
  },

  async read_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },

  async delete_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },
  async upload_Only(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  }

};
/*apiClient.interceptors.request.use(config=>{
  //config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjgwMDAvYXBpL2F1dGgtcmVxdWVzdC9jb25uZXhpb24iLCJpYXQiOjE2NzAwMzkwNTQsImV4cCI6MTY3MDA0MjY1NCwibmJmIjoxNjcwMDM5MDU0LCJqdGkiOiJrWGFYMEltQXJ5S08wMUFIIiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.ftn89907GCKBw_xUa9iiiQD-ccMTaBqn-OT4j6PvjRA`
return config;
});*/

export default apiClient;
