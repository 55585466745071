import api from '../../serviceApi/apiService'

export default {
namespace: true,

    state: {
            status_user: 0,
            status_compte: false,
            token_user: null,
            message_user: '',
            user: {
              id: 3,
              nom: '',
              prenom: '',
              email: '',
              email_verified_at: null,
              type_compte: '',
              type_profil: '',
              id_profil_ong: '',
              statut: '',
              code: '',
              created_at: '',
              updated_at: '',

            },
},
//= ======= Affichage des données de l'utilisateur ========//
getters: {
    getStatusUser (state) {
        return state.status_user
    },
    getStatusCompte (state) {
      return state.status_compte
  },
   getTokenUser (state) {
    return state.token_user
   },
    getMessageUser (state) {
        return state.message_user
    },

      getUser (state) {
        return state.user
      },
},
//= ======== recuperation  des données de l'utilisateur ========//
mutations: {

      setUser (state, value) {
        state.user = value
      },
      setStatusUser (state, value) {
        state.status_user = value
      },
      setStatusCompte (state, value) {
        state.status_compte = value
      },
      setTokenUser (state, value) {
        state.token_user = value
      },
      setMessageUser (state, value) {
        state.message_user = value
      },

},

//= ====fait action des donnees========
actions: {

  login: async function ({ commit }, datas) {
    const fd = new FormData()
    fd.append('email', datas.email)
    fd.append('password', datas.password)
     const data = await api.createUpdatedata('auth-request/connexion-ong', fd)
     if (data.status === 200) {
      commit('setStatusUser', data.status)
      commit('setTokenUser', data.access_token)
      commit('setMessageUser', 'Vous êtes connecté avec succès')
      commit('setUser', data.utilisateur)
      console.log(data)
     } else {
      commit('setStatusUser', data.success.status)
      commit('setMessageUser', data.success.message)
     }

      if (data.status === 200) {
        commit('setStatusCompte', true)
      }
  },

},

}
