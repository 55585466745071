/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

// import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";

//axio
import axios from "axios";

// Vue.component('apexchart', VueApexCharts);

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(octaviaKit);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

axios.defaults.baseURL = "https://web-api-sgp.piebarometre.digital/api/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token_fw");
//axios.defaults.headers.post["Content-Type"] = "application/json";
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
